import React, { useState } from "react";
import cn from "classnames";
import TextInput from "../../TextInput";
import styles from "../Page.module.sass";
import { toast } from "react-toastify";
import { useApiRequest } from "../../../apiHandler";

export default function ForgotPassword() {
  const { postApiRequest } = useApiRequest();
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  // Handle input change
  const getInputFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await postApiRequest(
        "/api/user-forget-password",
        formData
      );
      if (response?.status === 200) {
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cn(styles.login)}>
      <div className={styles.item}>
        <div className={cn("h3", styles.title)}>Forgot Password</div>
        <div className={styles.passwordText}>
          Please enter the email address you registered with below, and we will
          send you a link to reset your password.
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputRowBox}>
            <TextInput
              value={formData.email}
              onChange={getInputFormData}
              className={cn(styles.field, styles.smallField)}
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
              required
            />
          </div>
          <button
            className={cn("button", styles.button)}
            type="submit"
            disabled={loading}
          >
            {loading ? "Sending..." : "Reset Password"}
          </button>
        </form>
      </div>
    </div>
  );
}
