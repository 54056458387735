import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import Language from "./Language";
import Notification from "./Notification";
import User from "./User";
import Icon from "../Icon";
import Modal from "../Modal";
import SignIn from "../Page/Login";

const travelers = [
  {
    title: "Stays",
    url: "/",
    icon: "comment",
  },
  {
    title: "Flights",
    url: "/flights",
    icon: "email",
  },
  {
    title: "Things to do",
    url: "/things-to-do",
    icon: "home",
  },
  {
    title: "Cars",
    url: "/cars",
    icon: "email",
  },
];

const languages = [
  {
    title: "English",
    country: "United States",
    url: "/",
  },
  {
    title: "Vietnamese",
    country: "Vietnamese",
    url: "/",
  },
  {
    title: "Français",
    country: "Belgique",
    url: "/",
  },
  {
    title: "Français",
    country: "Canada",
    url: "/",
  },
];

const items = [
  {
    menu: [
      {
        title: "Messages",
        icon: "comment",
        url: "/messages",
      },
      {
        title: "Bookings",
        icon: "home",
        url: "/bookings",
      },
      {
        title: "Wishlists",
        icon: "email",
        url: "/wishlists",
      },
    ],
  },
  {
    menu: [
      {
        title: "List your property",
        icon: "building",
        url: "/list-your-property",
      },
      {
        title: "Host an experience",
        icon: "flag",
        url: "/your-trips",
      },
    ],
  },
];

const Header = ({ separatorHeader, wide, notAuthorized }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <div
        className={cn(
          styles.header,
          { [styles.headerBorder]: separatorHeader },
          { [styles.wide]: wide }
        )}
      >
        <div className={cn("container", styles.container)}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              src="https://content.1ki.ma/static-conent-1kima/assets/logo.webp"
              srcDark="https://content.1ki.ma/static-conent-1kima/assets/logo.webp"
              alt="KI"
            />
          </Link>
          <div
            className={cn(styles.wrapper, {
              [styles.active]: visibleNav,
            })}
          >
            <Dropdown
              className={styles.drowdown}
              items={travelers}
              setValue={setVisibleNav}
            />
            <NavLink
              className={cn(styles.link, {
                [styles.active]: pathname === "/support",
              })}
              to="/support"
            >
              Support
            </NavLink>
            <Language className={styles.language} items={languages} />
            <NavLink
              className={cn("button-stroke button-small", styles.button, {
                [styles.active]: pathname === "/list-your-property",
              })}
              to="/list-your-property"
            >
              List your property
            </NavLink>
          </div>
          <Notification className={styles.notification} />
          {localStorage.getItem("token") ? (
            <User className={styles.user} items={items} />
          ) : (
            <Link to="/sign-in">
              <button className={styles.login}>
                <Icon name="user" size="24" />
              </button>
            </Link>
          )}
          <button
            className={cn(styles.burger, {
              [styles.active]: visibleNav,
            })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <SignIn />
      </Modal>
    </>
  );
};

export default Header;