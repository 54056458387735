import axios from "axios";
import { decryptData } from "../utils";
import { useNavigate } from "react-router-dom";

export const serverUrl = process.env.REACT_APP_API_URL;

// Get Authorization headers with token
const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Centralized API request function
const apiRequest = async (method, endpoint, data = null, navigate = null) => {
  try {
    const response = await axios({
      method,
      url: `${serverUrl}${endpoint}`,
      headers: getAuthHeaders(),
      data,
    });
    if (response?.data?.status === 401 && navigate) {
      return null;
    }
    if (response?.data?.status === 200 && response?.data?.response) {
      const decryptedData = await decryptData(response?.data?.response);
      return { ...response.data, response: decryptedData };
    }
    return response.data;
  } catch (error) {
    const status = error.response?.status;
    const message = error.response?.data?.message || "An error occurred!";
    console.error("API Error:", error);
    return { status, message };
  }
};

// Custom hook for API requests
export const useApiRequest = () => {
  const navigate = useNavigate();
  const createRequest = (method) => (endpoint, data = null) =>
    apiRequest(method, endpoint, data, navigate);
  return {
    getApiRequest: createRequest("get"),
    postApiRequest: createRequest("post"),
    putApiRequest: createRequest("put"),
    deleteApiRequest: createRequest("delete"),
  };
};


export const getApiRequest = (endpoint) => apiRequest("get", endpoint);
export const postApiRequest = (endpoint, data) =>
  apiRequest("post", endpoint, data);
export const putApiRequest = (endpoint, data) =>
  apiRequest("put", endpoint, data);
export const deleteApiRequest = (endpoint) => apiRequest("delete", endpoint);
