import React, { useState } from "react";
import cn from "classnames";
import TextInput from "../../TextInput";
import styles from "../Page.module.sass";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useApiRequest } from "../../../apiHandler";

export default function ResetPassword() {
  const { postApiRequest } = useApiRequest();
  const [formData, setFormData] = useState({ password: "", confpassword: "" });
  const [loading, setLoading] = useState(false);
  const { access_token } = useParams();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confpassword: false,
  });
  const navigate = useNavigate();

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const toggleVisibility = (field) =>
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confpassword } = formData;
    if (password !== confpassword) {
      toast.error("Passwords do not match. Please try again.");
      return;
    }
    const data = { password, confirmPassword: confpassword, access_token };
    setLoading(true);
    try {
      const response = await postApiRequest("/api/user-reset-password", data);
      if (response?.status === 200) {
        toast.success(response?.message);
        setTimeout(() => {
          navigate("/");
        }, 1000);
        return;
      } else if (
        Array.isArray(response?.message) &&
        response.message.length > 0
      ) {
        const lastError = response.message[response.message.length - 1];
        toast.error(lastError);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cn(styles.login)}>
      <div className={styles.item}>
        <div className={cn("h3", styles.title)}>Reset Password</div>
        <form className={styles.form} onSubmit={handleSubmit}>
          {["password", "confpassword"].map((field) => (
            <div key={field} className={styles.inputRowBox}>
              <TextInput
                value={formData[field]}
                onChange={handleChange}
                className={cn(styles.field, styles.smallField)}
                label={field === "password" ? "Password" : "Confirm Password"}
                name={field}
                type={showPassword[field] ? "text" : "password"}
                placeholder={`Enter your ${
                  field === "password" ? "password" : "confirm password"
                }`}
                required
              />
              <span
                className={styles.eyeIcon65}
                onClick={() => toggleVisibility(field)}
              >
                {showPassword[field] ? (
                  <FaEyeSlash size={18} />
                ) : (
                  <FaEye size={18} />
                )}
              </span>
            </div>
          ))}
          <button
            className={cn("button", styles.button)}
            type="submit"
            disabled={loading}
          >
            {loading ? "Sending..." : "Reset Password"}
          </button>
        </form>
      </div>
    </div>
  );
}
