import React, { useState } from "react";
import cn from "classnames";
import Icon from "../../Icon";
import TextInput from "../../TextInput";
import styles from "../Page.module.sass";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useApiRequest } from "../../../apiHandler";

export default function SignIn() {
  const { postApiRequest } = useApiRequest();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Handle input change
  const getInputFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { status, response, message } = await postApiRequest(
        "/api/user-signin",
        formData
      );
      if (status === 200) {
        const { token } = response;
        localStorage.setItem("token", token);
        toast.success(message);
        navigate("/");
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cn(styles.login)}>
      <div className={styles.item}>
        <div className={cn("h3", styles.title)}>Sign in to Fleet</div>
        <div className={styles.info}>Use your credentials to sign in</div>
        <div className={styles.btns}>
          <button className={cn("button", styles.button)}>
            <Icon name="google" size="16" />
            <span>Google</span>
          </button>
          <button className={cn("button-black", styles.button)}>
            <Icon name="apple" size="16" />
            <span>Apple</span>
          </button>
        </div>
        <div className={styles.note}>Or continue</div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputRowBox}>
            <TextInput
              value={formData.email}
              onChange={getInputFormData}
              className={cn(styles.field, styles.smallField)}
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className={styles.inputRowBox}>
            <TextInput
              value={formData.password}
              onChange={getInputFormData}
              className={cn(styles.field, styles.smallField)}
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              required
            />
            <span
              className={styles.eyeIcon46}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
            </span>
            <div className={styles.forgotPassword}>
              <Link to="/forgot-password" className={styles.link}>
                Forgot Password?
              </Link>
            </div>
          </div>
          <button
            className={cn("button", styles.button)}
            type="submit"
            disabled={loading}
          >
            {loading ? "Signing in..." : "Sign in"}
          </button>
        </form>
        <div className={styles.foot}>
          Don’t have an account?{" "}
          <Link className={styles.link} to="/sign-up" rel="noopener noreferrer">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
}
