//  Converts a hex string to a Uint8Array :
function hexStringToUint8Array(hexString) {
  const byteArray = new Uint8Array(
    hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  );
  return byteArray;
}

//  Decrypts data that was encrypted with the AES-GCM algorithm using a secret key :
export async function decryptData(encryptedResponse) {
  const { iv, encryptedData, authTag } = encryptedResponse;
  const keyString = process.env.REACT_APP_ENCRYPTION_KEY;
  const ivBuffer = hexStringToUint8Array(iv);
  const encryptedBuffer = hexStringToUint8Array(encryptedData);
  const authTagBuffer = hexStringToUint8Array(authTag);
  const combinedBuffer = new Uint8Array([...encryptedBuffer, ...authTagBuffer]);
  try {
    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      new TextEncoder().encode(keyString),
      "AES-GCM",
      false,
      ["decrypt"]
    );
    const decrypted = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: ivBuffer,
        tagLength: 128,
      },
      cryptoKey,
      combinedBuffer
    );
    return JSON.parse(new TextDecoder().decode(decrypted));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
}
