import React, { useRef, useState } from "react";
import cn from "classnames";
import Icon from "../../Icon";
import TextInput from "../../TextInput";
import styles from "../Page.module.sass";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import { useApiRequest } from "../../../apiHandler";

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function SignUp() {
  const { postApiRequest } = useApiRequest();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    password: "",
    confirmPassword: "",
  });
  const recaptchaRef = useRef(null); // Ref for reCAPTCHA instance

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const navigate = useNavigate();

  // Handle input change
  const getInputFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Toggle password visibility
  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.executeAsync();
    if (!captchaToken) {
      toast.error("Merci de valider le Captcha");
    } else {
      const {
        firstname,
        lastname,
        email,
        telephone,
        password,
        confirmPassword,
      } = formData;

      // Validate password match
      if (password !== confirmPassword) {
        toast.error("Passwords do not match. Please try again.");
        return;
      }

      const data = {
        firstname,
        lastname,
        email,
        telephone,
        password,
        captchaToken,
      };

      setLoading(true);
      try {
        const { status, response, message } = await postApiRequest(
          "/api/user-signup",
          data
        );
        if (status === 200) {
          const { token } = response;
          localStorage.setItem("token", token);
          toast.success(message);
          navigate("/");
        } else if (Array.isArray(message)) {
          message?.forEach((val) => {
            toast.error(val, {});
          });
        } else {
          toast.error(message);
        }
      } catch (error) {
        console.error("API Error:", error);
        toast.error("An unexpected error occurred. Please try again.");
      } finally {
        setLoading(false);
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <div className={cn(styles.login)}>
      <div className={styles.item}>
        <div className={cn("h3", styles.title)}>Sign up to Fleet</div>
        <div className={styles.info}>Use your credentials to sign up</div>
        <div className={styles.btns}>
          <button className={cn("button", styles.button)}>
            <Icon name="google" size="16" />
            <span>Google</span>
          </button>
          <button className={cn("button-black", styles.button)}>
            <Icon name="apple" size="16" />
            <span>Apple</span>
          </button>
        </div>
        <div className={styles.note}>Or continue</div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputRowBox}>
            <TextInput
              value={formData.firstname}
              onChange={getInputFormData}
              className={cn(styles.field, styles.smallField)}
              label="First Name"
              name="firstname"
              placeholder="Enter your first name"
              required
            />
          </div>
          <div className={styles.inputRowBox}>
            <TextInput
              value={formData.lastname}
              onChange={getInputFormData}
              className={cn(styles.field, styles.smallField)}
              label="Last Name"
              name="lastname"
              placeholder="Enter your last name"
              required
            />
          </div>
          <div className={styles.inputRowBox}>
            <TextInput
              value={formData.email}
              onChange={getInputFormData}
              className={cn(styles.field, styles.smallField)}
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className={styles.inputRowBox}>
            <TextInput
              value={formData.telephone}
              onChange={getInputFormData}
              className={cn(styles.field, styles.smallField)}
              label="Telephone"
              name="telephone"
              type="tel"
              placeholder="Enter your telephone number"
              required
            />
          </div>
          <div className={styles.inputRowBox}>
            <TextInput
              value={formData.password}
              onChange={getInputFormData}
              className={cn(styles.field, styles.smallField)}
              label="Password"
              name="password"
              type={showPassword.password ? "text" : "password"}
              placeholder="Enter your password"
              required
            />
            <span
              className={styles.eyeIcon65}
              onClick={() => togglePasswordVisibility("password")}
            >
              {showPassword.password ? (
                <FaEyeSlash size={18} />
              ) : (
                <FaEye size={18} />
              )}
            </span>
          </div>
          <div className={styles.inputRowBox}>
            <TextInput
              value={formData.confirmPassword}
              onChange={getInputFormData}
              className={cn(styles.field, styles.smallField)}
              label="Confirm Password"
              name="confirmPassword"
              type={showPassword.confirmPassword ? "text" : "password"}
              placeholder="Confirm your password"
              required
            />
            <span
              className={styles.eyeIcon65}
              onClick={() => togglePasswordVisibility("confirmPassword")}
            >
              {showPassword.confirmPassword ? (
                <FaEyeSlash size={18} />
              ) : (
                <FaEye size={18} />
              )}
            </span>
          </div>
          <button
            className={cn("button", styles.button)}
            type="submit"
            disabled={loading}
          >
            {loading ? "Signing up..." : "Sign Up"}
          </button>
        </form>
        <div className={styles.foot}>
          Already have an account?{" "}
          <Link className={styles.link} to="/sign-in" rel="noopener noreferrer">
            Sign in
          </Link>
          <ReCAPTCHA sitekey={SITE_KEY} size="invisible" ref={recaptchaRef} />
        </div>
      </div>
    </div>
  );
}
